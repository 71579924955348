export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const fetchUser = userdata => {
  return dispatch => {
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: userdata
    });
  };
};

export const resetLoginState = () => {
  return dispatch=>{
    dispatch({type:"RESET"})
  }
}