import React, { Component } from "react";
import { connect } from "react-redux";

import { theme } from "../../components";

import {
  Anchor,
  Avatar,
  Box,
  Button,
  Collapsible,
  Grommet,
  Image,
  Layer,
  ResponsiveContext,
  Text,
} from "grommet";

import { Menu, User, Close, Logout } from "grommet-icons";
import { clear } from "redux-localstorage-simple";
import { resetLoginState } from "../../redux/actions/userActions";
import { ticketUpdate } from "../../redux/actions/notificationActions";
import { withRouter } from "react-router-dom";
import { apiconn } from "../../apiconn";
import axios from "axios";
import { Config } from "../../Config/index";

let baseURL = Config.API_IMG_URL;

class Header extends Component {
  cancelToken = axios.CancelToken.source();
  apiheader = {
    headers: { Authorization: "Bearer " + this.props.login.token },
    cancelToken: this.cancelToken.token,
  };
  constructor(props) {
    super(props);

    this.state = {
      desktopNav: true,
      layerNav: false,
      sidebarPanel: false,
    };
  }

  toggleMenu() {
    this.setState({ desktopNav: this.state.desktopNav ? false : true });
  }

  toggleSidebar() {
    this.setState({ sidebarPanel: this.state.sidebarPanel ? false : true });
  }
  doLogout(unauthorized = false) {
    if (unauthorized) {
      clear();
      this.props.resetLoginState();
      this.props.history.replace("/");
    } else {
      clear();
      this.props.resetLoginState();
      this.props.history.replace("/", { message: "Sesi berakhir, silahkan login ulang." });
    }
  }

  async getWidget() {
    let apipath = '/admin/widget'
    let postData = {
      id: this.props.user.id,
      admin: this.props.user.role?.role === "admin" ? true : false
    }
    await apiconn.postApi
      .post(apipath, postData, this.apiheader)
      .then((response) => {
        if (response.status >= 200 && response.status <= 202) {
          setTimeout(() => {
            this.getWidget()
          }, 3000)
          this.props.ticketNotification({ ticket: response.data?.notRespondedTicket > 0, ...response?.data })
        } else if (response.status === 203) {
          console.warn(response)
        } else {
          console.error("Request failed");
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Async terminated successfully!");
        } else {
          console.warn(error.message);
        }
      })
  }

  async _fetchUserData() {
    if (this.props.id_user < 1) {
      this.doLogout();
    } else {
      apiconn.getApi
        .get("/user/" + this.props.id_user, this.apiheader)
        .then((response) => {
          if (response.status >= 200 && response.status <= 202) {
            setTimeout(() => {
              this._fetchUserData();
            }, 3000);
          } else if (response.status === 203) {
            //fail
            this.doLogout();
          } else {
            console.log("Request failed");
          }
        })
        .catch((error) => {
          if (error !== undefined) {
            if (error.response !== undefined) {
              if (error.response.status !== undefined) {
                if (error.response.status === 401) {
                  //unauthorized
                  this.doLogout();
                }
              }
            }
          }
        });
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidMount() {
    this._fetchUserData();
    this.getWidget()
  }

  render() {
    return (
      <Grommet theme={theme}>
        <ResponsiveContext.Consumer>
          {(size) => (
            <>
              <Box
                id="top-panel"
                direction="row"
                align="center"
                justify="between"
              >
                {size !== "small" ? (
                  <Box id="logo">
                    <Image src="/logo.png" />
                  </Box>
                ) : (
                  <Menu
                    className="clickable"
                    color="black"
                    onClick={() => this.openNav()}
                  />
                )}
                <Box
                  className="panel-content"
                  direction="row"
                  align="center"
                  gap="15px"
                >
                  <Box id="account-panel">
                    <Box direction="row" align="center" gap="xsmall">
                      <Image
                        className="clickable rounded-large"
                        src={
                          this.props.user.avatar !== null
                            ? `${baseURL}avatarprofile/${this.props.user.avatar}`
                            : "/avatar.png"
                        }
                        onClick={(e) => this.toggleSidebar()}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Collapsible
                direction="horizontal"
                open={this.state.sidebarPanel}
              >
                <Layer
                  className="sidebar-layer"
                  full="vertical"
                  position="right"
                >
                  <Box pad="20px" fill="vertical">
                    <Box align="end">
                      <Button
                        icon={<Close size="17px" color="#757575" />}
                        plain
                        onClick={() => this.toggleSidebar()}
                      />
                    </Box>
                    <Box className="account-panel" gap="50px" fill="vertical">
                      <Box align="center" gap="10px" flex={false}>
                        <Avatar
                          src={
                            this.props.user.avatar !== null
                              ? `${baseURL}avatarprofile/${this.props.user.avatar}`
                              : "/avatar.png"
                          }
                          size="90px"
                        />
                        <Box align="center" gap="2px">
                          <Text>
                            {this.props.user.first_name +
                              " " +
                              this.props.user.last_name}
                          </Text>
                          <Text size="small">
                            @{this.props.user.display_name}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        className="account-panel-menu"
                        gap="10px"
                        justify="between"
                        pad={{ horizontal: "15px" }}
                        flex="grow"
                      >
                        <Box flex="grow" overflow={{ vertical: "auto" }}>
                          <Anchor
                            onClick={() =>
                              this.props.history.replace({
                                pathname:
                                  this.props.user.role.role !== "admin"
                                    ? "/profile"
                                    : "/admin/profile",
                              })
                            }
                          >
                            <Box direction="row" align="center" gap="12px">
                              <User size="16px" color="accent-1" />
                              <Text className="no-line-height" size="small">
                                Profil Saya
                              </Text>
                            </Box>
                          </Anchor>
                        </Box>
                        <Box>
                          <Button
                            className="button-red"
                            label={
                              <Text size="xsmall" color="#ff4040">
                                Logout
                              </Text>
                            }
                            icon={<Logout size="15px" color="#ff4040" />}
                            size="small"
                            fill="horizontal"
                            onClick={() => this.doLogout(true)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Layer>
              </Collapsible>
            </>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData,
    id_user: state.userData.id,
    login: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetLoginState: () => {
      dispatch(resetLoginState());
    },
    ticketNotification: (value) => {
      dispatch(ticketUpdate(value))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
