import { FETCH_USER_SUCCESS } from "../actions/userActions";
const initState = {
  
};

const userReducer = (state = initState, action) => {
  if (action.type === FETCH_USER_SUCCESS) {
    return {
      ...state,
      ...action.payload
    };
  }

  if (action.type ==="RESET") {
    return initState;
  }
  
  return state;
};

export default userReducer;
