import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

const MyService = lazy(() => import("./pages/MyService"))
const Billing = lazy(() => import("./pages/Billing"));
const BillingDetail = lazy(() => import("./pages/BillingDetail"));
const Checkout = lazy(() => import("./pages/Checkout"));
const HelpDesk = lazy(() => import("./pages/HelpDesk"));
const Home = lazy(() => import("./pages/Home"));
const Profile = lazy(() => import("./pages/Profile"));
const Security = lazy(() => import("./pages/Security"));
const Services = lazy(() => import("./pages/Services"));
const Signin = lazy(() => import("./pages/Signin"));
const Signup = lazy(() => import("./pages/Signup"));
const AdminHome = lazy(() => import("./pages/AdminHome"));
const AdminPackets = lazy(() => import("./pages/AdminPackets"));
const AdminCustomers = lazy(() => import("./pages/AdminCustomers"));
const AdminStaff = lazy(() => import("./pages/AdminStaff"));
const PaymentInfo = lazy(() => import("./pages/PaymentInfo"));
const Voucher = lazy(() => import("./pages/Voucher"));
const AdminVouchers = lazy(() => import("./pages/AdminVouchers"));
const AdminHelpDesks = lazy(() =>
  import("./pages/AdminHelpDesks/AdminHelpDesks")
);
const AdminBilling = lazy(() => import("./pages/AdminBilling"));
const AdminBillingDetail = lazy(() => import("./pages/AdminBillingDetail"));

function App() {
  const userRole = useSelector((state) => state.userData.role);
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          {userRole ? (
            userRole.role === "admin" ? (
              <Switch>
                <Route
                  path={process.env.PUBLIC_URL + "/admin/home"}
                  exact
                  component={AdminHome}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/profile"}
                  exact
                  render={(props) => <Profile {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/packets"}
                  exact
                  render={(props) => <AdminPackets {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/customers"}
                  exact
                  render={(props) => <AdminCustomers {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/staff"}
                  exact
                  render={(props) => <AdminStaff {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/vouchers"}
                  exact
                  render={(props) => <AdminVouchers {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/helpdesks"}
                  exact
                  render={(props) => <AdminHelpDesks {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/billing"}
                  exact
                  render={(props) => <AdminBilling {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/admin/billing-detail/:id"}
                  exact
                  render={(props) => <AdminBillingDetail {...props} />}
                />
                <Redirect to={"/admin/home"} />
              </Switch>
            ) : (
              <Switch>
                <Route
                  path={process.env.PUBLIC_URL + "/paymentinfo/:xendit?"}
                  exact
                  render={(props) => <PaymentInfo {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/billing"}
                  exact
                  render={(props) => <Billing {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-service"}
                  exact
                  render={(props) => <MyService {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/voucher"}
                  exact
                  component={Voucher}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/billing-detail/:id"}
                  exact
                  render={(props) => <BillingDetail {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout/:id"}
                  exact
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/help-desk"}
                  exact
                  render={(props) => <HelpDesk {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/home"}
                  exact
                  component={Home}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/profile"}
                  exact
                  render={(props) => <Profile {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/security"}
                  exact
                  render={(props) => <Security {...props} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/services"}
                  exact
                  render={(props) => <Services {...props} />}
                />
                <Redirect to={"/home"} />
              </Switch>
            )
          ) : (
            <Switch>
              <Route
                path={process.env.PUBLIC_URL + "/signup"}
                exact
                render={(props) => <Signup {...props} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/"}
                exact
                render={(props) => <Signin {...props} />}
              />
              <Redirect to={"/"} />
            </Switch>
          )}
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
