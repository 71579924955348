// import productReducer from "./productReducer";
// import wishlistReducer from "./wishlistReducer";
// import cartReducer from "./cartReducer";
import loginReducer from "./loginReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  // productData: productReducer,
  // wishlistData: wishlistReducer,
  // cartData: cartReducer,
  loginData: loginReducer,
  userData: userReducer,
  notification: notificationReducer
});

export default rootReducer;
