import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import {
  Dashboard,
  AppsRounded,
  Contact,
  DocumentText,
  Ticket,
  Lock,
  Group,
  Catalog,
  Tag,
  Chat,
} from "grommet-icons";
import { Box, Text, Tip } from "grommet";

class SidebarMenu extends Component {
  render() {
    return (
      <>
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId={window.location.pathname}
          onSelect={({ itemId }) => {
            if (itemId.toString().length >= 1) {
              this.props.history.replace(itemId);
            }
          }}
          items={
            this.props.user.role && this.props.user.role.role === "user"
              ? [
                  {
                    title: "Beranda",
                    itemId: "/home",
                    elemBefore: () => <Dashboard />,
                  },
                  {
                    title: "Layanan",
                    itemId: "/services",
                    elemBefore: () => <AppsRounded />,
                  },
                  {
                    title: "Tagihan",
                    itemId: "/billing",
                    elemBefore: () => <DocumentText />,
                  },
                  {
                    title: "Voucher",
                    itemId: "/voucher",
                    elemBefore: () => <Ticket />,
                  },
                  {
                    title: "Keamanan",
                    itemId: "/security",
                    elemBefore: () => <Lock />,
                  },
                  {
                    title: (
                      <Box direction="row" align="center" gap="5px">
                        <Box className="notify-container">Dukungan </Box>
                        {this.props.notification?.notRespondedTicket > 0 && (
                          <Box>
                            <Tip
                              content={
                                <Text size="xsmall">
                                  {this.props.notification.notRespondedTicket}{" "}
                                  pesan aktif
                                </Text>
                              }
                            >
                              <Text size="small">
                                ({this.props.notification.notRespondedTicket})
                              </Text>
                            </Tip>
                          </Box>
                        )}
                      </Box>
                    ),
                    itemId: "/help-desk",
                    elemBefore: () => <Contact />,
                  },
                ]
              : [
                  {
                    title: "Home",
                    itemId: "/admin/home",
                    elemBefore: () => <Dashboard />,
                  },
                  {
                    title: "Bills",
                    itemId: "/admin/billing",
                    elemBefore: () => <DocumentText />,
                  },

                  {
                    title: "Customers",
                    itemId: "/admin/customers",
                    elemBefore: () => <Group />,
                  },
                  {
                    title: "Admin/Staff",
                    itemId: "/admin/staff",
                    elemBefore: () => <Group />,
                  },
                  {
                    title: "Packages",
                    itemId: "/admin/packets",
                    elemBefore: () => <Catalog />,
                  },
                  {
                    title: "Vouchers",
                    itemId: "/admin/vouchers",
                    elemBefore: () => <Tag />,
                  },
                  {
                    title: (
                      <Box direction="row" align="center" gap="5px">
                        <Box className="notify-container">Dukungan </Box>
                        {this.props.notification?.notRespondedTicket > 0 && (
                          <Box>
                            <Tip
                              content={
                                <Text size="xsmall">
                                  {this.props.notification.notRespondedTicket}{" "}
                                  pesan aktif
                                </Text>
                              }
                            >
                              <Text size="small">
                                ({this.props.notification.notRespondedTicket})
                              </Text>
                            </Tip>
                          </Box>
                        )}
                      </Box>
                    ),
                    itemId: "/admin/helpdesks",
                    elemBefore: () => <Chat />,
                  },
                ]
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData,
    notification: state.notification,
    login: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
