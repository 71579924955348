const initState = {
  token: "",
};

const loginReducer = (state = initState, action) => {
  // console.log(action);
  if (action.type === "SAVE_LOGIN") {
    return {
      ...state,
      token: action.payload,
    };
  }

  if (action.type === "RESET") {
    return initState;
  }

  return state;
};

export default loginReducer;
