const initState = {
  ticket: false,
  notRespondedTicket: 0,
  notPaid: 0,
  onProgress: 0
};

const notificationReducer = (state = initState, action) => {
  // console.log(action);
  if (action.type === "ticketNotification") {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === "RESET") {
    return initState;
  }

  return state;
};

export default notificationReducer;
